<template>
	<div>
		<Navbar />
		<Header title="Create Contact" excerpt="Create new contacts and assign them to different groups." />

		<!-- CONTENT -->
		<div class="container">
			<div class="row">
				<div class="col-md-8">
					<div class="alert alert-success" v-if="form.success">{{ form.success }}</div>
					<div class="alert alert-danger" v-if="form.error">{{ form.error }}</div>
					<form>
						<div class="form-row">
							<div class="col">
								<div class="form-group">
									<label>First Name</label>
									<input class="form-control" type="text" v-model="contact.first_name" />
								</div>
							</div>
							<div class="col">
								<div class="form-group">
									<label>Last Name</label>
									<input class="form-control" type="text" v-model="contact.last_name" />
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="col">
								<div class="form-group">
									<label>Number</label>
									<input class="form-control" type="text" v-model="contact.number" />
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="row">
				<div class="col mt-3">
					<button v-on:click="On_Submit" class="btn btn-primary">Save</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import config from "../../../config.json"
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";

export default {
	name: "CreateContacts",
	components: { Header, Navbar },

	data: () => ({
		contact: {
			first_name: null,
			last_name: null,
			number: null
		},
		form: {
			error: null,
			success: null
		}
	}),

	methods: {

		On_Submit() {

			/**
			 * Reset form errors and success notice because this
			 * is going to be new submission to contact creation
			 * and we don't need any of the previous errors or
			 * anything
			 */
			this.form.success = null
			this.form.error = null

			/**
			 * Submit new contact information to the API and behave
			 * according to the return response from the API
			 */
			fetch(config.api.protocol + "://" + config.api.fqdn + "/v1.0/contact", {
				method: "POST",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") },
				body: JSON.stringify({
					first_name: this.contact.first_name,
					last_name: this.contact.last_name,
					number: this.contact.number
				})
			}).then(Response => Response.json()).then(Response => {
				if (Response.status == "success") {
					this.form.success = "Contact created successfully."
				} else {
					if (Response.status == "failed") {
						this.form.error = Response.error.message
					} else {
						this.form.error = "Something went wrong"
					}
				}
			})

		}

	}
}
</script>
